* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.chat-main-div{
  background-color: #fff !important;
  position: relative;
  z-index: 0;
}
.psize {
  width: 100%;
  margin: auto;
  font-family: sans-serif;
}

.psize:after {
  height: 389px;
  content: "";
  display: block;
  width: 100%;
  position: fixed;
  top: 0%;
  left: 0;
  transform: translateZ(0);
  z-index: -1;
  background-color: #333132;
}

.chat-head {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.chat-u {
  display: flex;
  gap: 3px;
}

.chat-u img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.chat-b h1 {
  color: #DAD8D9;
  font-size: 32px;
  margin-top: 90px;
}

.chat-b h1 span {
  font-size: 24px;
  display: block;
  margin-top: 10px;
}

.chat-b {
  padding: 15px;
}

.send-box {
  background: #fff;
  width: 100%;
  border-radius: 10px;
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: 20px;
  box-shadow: #00000014 0 0 0 1px, #0000000f 0 2px 8px;
}

.send-box input {
  height: 50px;
  width: 100%;
  padding: 0 50px 0 15px;
}

.send-box button {
  position: absolute;
  background: none;
  right: 10px;
  top: 18px;
  border: none;
}

.alternet-sites {
  width: 100%;
  margin-top: 15px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: #00000014 0 0 0 1px, #0000000f 0 2px 8px;
}

.alternet-sites a {
  text-decoration: none;
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.asite {
  display: flex;
  justify-content: space-between;
  color: #333;
  align-items: center;
}

.asite img {
  width: 26px;
}

.promo {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  display: block;
  background: #fff;
  border-radius: 10px;
}

.promo h3 {
  font-size: 14px;
  margin-bottom: 10px;
}

.promo img {
  width: 100%;
  max-width: 100%;
}

.promo p,
.promo b {
  margin-bottom: 20px;
  display: block;
  font-size: 13px;
}

.chat-footer {
  width: 100%;
  display: flex;
  height: 80px;
  align-items: center;
  border-top: #e7e4e4 thin solid;
  position: fixed;
  bottom: 0;
  background: #fff;
}

.chat-footer a {
  width: 33%;
  color: #333;
  text-decoration: none;
  font-size: 13px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}

.chat-footer a svg {
  margin: auto;
}
.zmdi { color: #fff !important;
  font:normal normal normal 14px/1 'Material-Design-Iconic-Font'
 }

 .conversation-container .message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  border-radius: 7.5px 7.5px 7.5px 0px;
  box-shadow: 0 1px .5px rgba(11,21,26, .13);
  background: #fff;     overflow: initial;
}
.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}
.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.message.sent {
  background: #d9fdd3;
  float: right;
  box-shadow: 0 1px .5px rgba(11,21,26, .13);
  border-radius: 7.5px 0px 7.5px 7.5px;
}

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.message button#dropdown-item-button {
  padding: 0;
  text-align: right;
  float: right;
  background: transparent;
  margin-top: 2px;
  margin-right: 0;
  margin-left: 5px;
}

.message .dropdown-menu.show {
  display: block;
  padding: 0;
  font-size: 12px;
  width: auto;
  min-width: auto;
  transform: translate3d(-0.2px, 19.1429px, 0px) !important;
  right: 0 !important;
  left: auto !important;
}

.message span.status-icon {
  float: right;
  bottom: -6px;
  font-size: 14px;
  position: relative;
  color: #53bdeb;
}